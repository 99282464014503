.kyc-container {
  background: #ececec;
  height: 100vh;
  overflow: auto;
  padding: 25px 150px;

  @media (max-width: 767.98px) {
    padding: 0;
    height: 100%;
  }
}

.kyc-container .kyc-body {
  background: white;
  border-radius: 20px;
  padding: 20px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 767.98px) {
    padding: 0;
    height: 100%;
  }

  @media (max-width: 1050px) {
    padding: 0;
  }
}

.kyc-container .kyc-body .logo {
  position: absolute;
  top: 30px;
  left: 30px;
}

.kyc-container .kyc-body .input-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.kyc-container .kyc-body .input-box h1 {
  padding: 10px;
}

.kyc-container .kyc-body .input-box input {
  border-radius: 10px;
}

@media (max-width: 767.98px) {
  .kyc-container .kyc-body .input-box {
    width: 100%;
  }
}

.item {
  margin-top: 20px;
  height: 60px;
  width: 100%;
  font-size: larger;
}

.userdiv {
  border-bottom: 1px solid #8282ec;
  font-weight: bold;
  text-align: center;
  font-size: 25px;
  padding: 10px;
  gap: 10px;
  margin-top: 10px;
  width: 300px;
}

.done-tick {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FieldContainer {
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.FieldContainer .item {
  width: 100%;
}
