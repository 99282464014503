.CardContainer {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}

.btn-container {
  grid-column: 1/-1;
}

.grid-full-width {
  grid-column: 1/-1;
}
